@if(notify.msg | async ;as msg){
<div class="notification" [ngClass]="msg.style" fxLayout="column">
    <div class="notification-header flex-row">
        <mat-icon class="medium-icon notification-icon" [color]="getStyleColor(msg.style)">notifications</mat-icon>
        <h4>{{ msg.header || 'Notification' }}</h4>
        <div class="spacer"></div>
        <button mat-icon-button (click)="notify.clear()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
    <div class="message-content">{{ msg.content }}</div>
</div>
}