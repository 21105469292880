import { Routes } from '@angular/router';
import { authGuard } from './auth.guard';

const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./auth/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
  },
  { path: 'auth', redirectTo: '/login', pathMatch: 'full' },
];

const defaultRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: '**',
    loadComponent: () => import('./error/path-not-found/path-not-found.component').then((c) => c.PathNotFoundComponent),
  },
];

const appRoutes: Routes = [
  {
    path: 'app',
    loadComponent: () => import('./pages/main-app/main-app.component').then((c) => c.MainAppComponent),
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./components/dashboard/dashboard.component').then((c) => c.DashboardComponent),
      },
      {
        path: 'calendar',

        loadChildren: () => import('./components/calendar/my-calendar.module').then((m) => m.MyCalendarModule),
      },
      {
        path: 'case-list/:status',
        loadComponent: () => import('./components/case-list/case-list.component').then((c) => c.CaseListComponent),
      },
      {
        path: 'admin',
        loadComponent: () =>
          import('./components/admin-panel/admin-panel.component').then((c) => c.AdminPanelComponent),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
    ],
    canActivate: [authGuard],
  },
];

const caseRoutes: Routes = [
  {
    path: 'case-details',
    loadComponent: () => import('./pages/case-details/case-details.component').then((c) => c.CaseDetailsComponent),
    children: [
      {
        path: 'demographics',
        loadComponent: () =>
          import('./components/patient-information/patient-information.component').then(
            (c) => c.PatientInformationComponent
          ),
      },
      {
        path: 'appointments',
        loadComponent: () =>
          import('./components/appointments/appointments.component').then((c) => c.AppointmentsComponent),
      },
      {
        path: 'history',
        loadComponent: () => import('./components/history/history.component').then((c) => c.HistoryComponent),
      },
      {
        path: 'disability',
        loadComponent: () => import('./components/disability/disability.component').then((c) => c.DisabilityComponent),
      },
      {
        path: 'documents',
        loadComponent: () => import('./components/documents/documents.component').then((c) => c.DocumentsComponent),
      },
      {
        path: 'reports',
        loadComponent: () => import('./components/reports/generator/reports-list/reports-list.component').then((c) => c.ReportsListComponent),
      },
      {
        path: 'notes',
        loadComponent: () => import('./components/notes/notes.component').then((c) => c.NotesComponent),

      },
      {
        path: 'issues',
        loadComponent: () => import('./components/issue/issue.component').then((c) => c.IssueComponent),
      },
      {
        path: 'report/view',
        loadComponent: () => import('./components/reports/generator/report-viewer/report-viewer.component').then((c) => c.ReportViewerComponent),
      },
      {
        path: 'report/edit',
        loadComponent: () => import('./components/reports/generator/report-editor/report-editor.component').then((c) => c.ReportEditorComponent),
      }
    ],
  },
];

const userRoutes: Routes = [
  {
    path: 'user-account',
    loadComponent: () => import('./pages/user-account/user-account.component').then((c) => c.UserAccountComponent),
    children: [
      {
        path: 'info',
        loadComponent: () => import('./pages/user-account/info/info.component').then((c) => c.InfoComponent),
      },
      {
        path: 'security',
        loadComponent: () => import('./pages/user-account/security/security.component').then((c) => c.SecurityComponent),
      },
      {
        path: 'helpdesk',
        loadComponent: () => import('./pages/user-account/helpdesk/helpdesk.component').then((c) => c.HelpdeskComponent),
      },
      { path: '', redirectTo: 'info', pathMatch: 'prefix' },
    ],
  },
];

export const routes: Routes = [...authRoutes, ...appRoutes, ...caseRoutes, ...userRoutes, ...defaultRoutes];
