import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ToastService } from '../toast-message/toast-service.service';

@Component({
  selector: 'app-toast-message-component',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './toast-message-component.component.html',
  styleUrl: './toast-message-component.component.scss'
})
export class ToastMessageComponent {
  private messageStyles: { [key: string]: string } = {
    error: 'warn',
    info: 'primary',
    success: 'accent',
  };
  public notify = inject(ToastService);

  getStyleColor(style: any): string {
    const styleColor = style ? this.messageStyles[style] : '';
    return styleColor;
  }
}
